import classes from './Header.module.css'

const Header = () => {
    return (
        <header className={classes.parent}>
            <h1 className={classes.header}>Send Temps</h1>
            <p className={classes.description}>Coming soon</p>
        </header>
    )
}

export default Header;