import React from "react";
import Header from "./components/Header";
import classes from "./App.module.css";

const App = () => {
  return (
    <div className={classes.container}>
      <div className={classes.app}>
        <Header />
      </div>
    </div>
  );
}

export default App;
